<template>
    <layout-main ref="main" class="page-404">
        <main class="wrap-main page-404__main">
            <section class="section page-404__section">
                <div class="container page-404__container">
                    <div class="page-404__content">
                        <img src="../../../images/inhtml/404.png" alt="404" class="page-404__img" />
                        <div class="page-404__error">
                            <h1 class="page-404__hl">404</h1>
                            <p class="page-404__desc">
                                Страница не найдена,<br />
                                но самый лучший курс найдётся!
                            </p>
                            <v-button href="/" class="page-404__btn1">на главную</v-button>
                            <v-button href="/courses">учебные курсы</v-button>
                        </div>
                    </div>
                </div>
            </section>
        </main>
    </layout-main>
</template>

<script>
import VButton from '../../components/controls/VButton/VButton.vue';

import './Page404.css';

export default {
    name: 'page-404',
    components: {
        VButton,
    },
};
</script>
