var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout-main", { ref: "main", staticClass: "page-404" }, [
    _c("main", { staticClass: "wrap-main page-404__main" }, [
      _c("section", { staticClass: "section page-404__section" }, [
        _c("div", { staticClass: "container page-404__container" }, [
          _c("div", { staticClass: "page-404__content" }, [
            _c("img", {
              staticClass: "page-404__img",
              attrs: {
                src: require("../../../images/inhtml/404.png"),
                alt: "404"
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "page-404__error" },
              [
                _c("h1", { staticClass: "page-404__hl" }, [_vm._v("404")]),
                _vm._v(" "),
                _c("p", { staticClass: "page-404__desc" }, [
                  _vm._v("\n                            Страница не найдена,"),
                  _c("br"),
                  _vm._v(
                    "\n                            но самый лучший курс найдётся!\n                        "
                  )
                ]),
                _vm._v(" "),
                _c(
                  "v-button",
                  { staticClass: "page-404__btn1", attrs: { href: "/" } },
                  [_vm._v("на главную")]
                ),
                _vm._v(" "),
                _c("v-button", { attrs: { href: "/courses" } }, [
                  _vm._v("учебные курсы")
                ])
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }